@import 'inline-svg';

$bg: #a7967e;
$bg-darker: #55463f;
$bg-darkest: #222222;
$bg-card: #fcfbfa;

$fg: #8394a8;
$fg-lighter: #aab8c7;
$fg-lightest: #edf2f8;
$fg-card: #72706f;
$fg-card-darker: #44372f;

$hl: #ff4444;

@mixin h1 {
	font-size: 1.5rem;
	line-height: 2rem;
	color: $hl;
}

@mixin h2($font-size: 1.2rem, $line-height: 2rem) {
	font-size: $font-size;
	font-weight: 500;
	letter-spacing: 0.1rem;
	line-height: $line-height;
	text-transform: uppercase;
}

@mixin media-large {
	@media (min-width: 800px) {
		@content;
	}
}

$logo: inline-svg(
	''
);
