@charset "UTF-8";
/*! mishalpatel.com */
*, *:after, *:before {
  margin: 0;
  padding: 0;
  border: 0;
  font: inherit;
  box-sizing: border-box;
  border-radius: 0; }

html,
body {
  min-height: 100vh;
  background: #a7967e;
  color: #8394a8;
  font-family: 'Lato', 'Avenir Next', AvenirNext, Avenir, 'gill sans', 'gill sans mt', Calibri, sans-serif;
  -webkit-tap-highlight-color: rgba(255, 255, 255, 0); }

html {
  font-size: 62.5%; }

body {
  padding: 1.5rem;
  font-size: 1.4rem;
  line-height: 2rem;
  background-repeat: no-repeat;
  background: #222222; }

a:link, a:visited {
  color: inherit;
  text-decoration: none; }

a:focus, a:hover {
  text-decoration: underline; }

p + * {
  margin-top: 1rem; }

ul,
li {
  display: block;
  list-style: none; }

li + * {
  margin-top: 1rem; }

li > a {
  display: block;
  margin: -0.5rem 0;
  padding: 0.5rem 0; }

.stripes,
[class^='stripe-'] {
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  overflow: hidden;
  transform-style: preserve-3d; }

.stripes {
  position: fixed;
  z-index: 0;
  min-height: 100%; }

[class^='stripe-'] {
  position: absolute;
  transition: transform 0.5s ease-out; }

.stripe-a {
  z-index: 1;
  transform: translateZ(80rem) translateX(calc(7% - 16.7rem));
  background-image: linear-gradient(103.2deg, #a7967e 50.1%, transparent 50%);
  transition-delay: 0.1s; }

.stripe-b {
  z-index: 0;
  transform: translateX(calc(7% - 11.3rem));
  background-image: linear-gradient(103.2deg, #55463f 50.1%, transparent 50%); }

.stripe-c {
  z-index: 0;
  transform: translateX(calc(7%));
  background-image: linear-gradient(103.2deg, transparent 50%, #ff4444 50%);
  transition-delay: 0; }

.stripe-d {
  z-index: 1;
  transform: translateX(calc(7% + 0.8rem));
  background-image: linear-gradient(103.2deg, transparent 50%, #a7967e 50%);
  transition-delay: 0.01s; }

.flipped .stripes {
  pointer-events: none; }

.flipped .stripe-a {
  transform: translateX(calc(7% - 16.7rem + -30%));
  transition-delay: 0; }

.flipped .stripe-b {
  transform: translateX(calc(7% - 11.3rem + -30%));
  transition-delay: 0.05s; }

.flipped .stripe-c {
  transform: translateX(calc(7% + 21%));
  transition-delay: 0.01s; }

.flipped .stripe-d {
  transform: translateX(calc(7% + 0.8rem + 21%));
  transition-delay: 0; }

header img {
  display: none; }

header p {
  font-size: 1.2rem;
  font-weight: 500;
  letter-spacing: 0.1rem;
  line-height: 2rem;
  text-transform: uppercase;
  color: #44372f; }
  header p span {
    display: block; }
    @media (min-width: 800px) {
      header p span {
        display: inline; }
        header p span ~ :before {
          content: ' · '; } }

header a {
  text-decoration: none; }

header + * {
  margin-top: 3rem; }

h1 {
  font-size: 1.5rem;
  line-height: 2rem;
  color: #ff4444;
  /*	.given-name, .family-name {
		font-size: 5.5rem;
		line-height: 1.2911289006em;
		color: #3c3232;
		font-family: 'Lato', sans-serif;
		font-weight: 300;
		clear: both;
		letter-spacing: 1px;	
		text-shadow: 1px 3px 6px rgba(0, 0, 0, .1);
	} */ }
  h1 a {
    cursor: default; }
    h1 a:hover, h1 a:focus {
      text-decoration: none; }

h2 {
  font-size: 1.2rem;
  font-weight: 500;
  letter-spacing: 0.1rem;
  line-height: 2rem;
  text-transform: uppercase;
  margin: 3rem 0 1rem;
  color: #edf2f8; }

h3 {
  color: #aab8c7; }

.type, .phone {
  position: absolute;
  top: 0;
  left: 0;
  width: 0;
  height: 0;
  overflow: hidden; }

aside,
section {
  position: relative;
  padding: 0 3rem; }

.card,
.wrap {
  z-index: 500; }

.wrap {
  transform: translateZ(0px); }

.scene {
  position: relative;
  width: 100%;
  min-height: calc(100vh - 3rem);
  transform-origin: 50% 50%;
  perspective-origin: 50% 55%;
  perspective: 80rem;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-flow: column nowrap; }

.card {
  padding: 3rem;
  background: #fcfbfa;
  color: #72706f;
  box-shadow: 0 1px 2px 0 rgba(42, 33, 27, 0.15), 0 2px 4px 0 rgba(42, 33, 27, 0.06), 0 3px 14px 0 rgba(43, 34, 27, 0.1), 0 6px 35px 0 rgba(43, 34, 28, 0.25); }

.js .more {
  display: none;
  background: #000000a6; }
  @media (min-width: 800px) {
    .js .more {
      background: none; } }

.js .flipped .more {
  display: grid;
  padding-bottom: 1rem; }

.js .card,
.js .wrap,
.js .more {
  max-width: 48rem;
  width: 100%; }

.fullname {
  max-width: 33rem;
  min-width: 29rem;
  margin: 0 auto;
  max-height: 6.5rem;
  min-height: 5.5rem;
  /*background: $logo 50% 50% no-repeat;*/
  background-size: 100%; }
  .fullname:before {
    display: block;
    content: 'Mishal Patel';
    font-family: 'Lato', sans-serif;
    font-size: 5.5rem;
    /*padding-bottom: 6.5rem / 33rem * 100%;*/ }

.wrap {
  position: relative;
  transform-style: preserve-3d;
  transform: rotateY(0deg); }

.flip {
  transform: rotateY(180deg);
  animation: flip 0.6s linear normal; }

.unflip {
  transform: rotateY(0);
  animation: unflip 0.5s linear normal; }

.front,
.back {
  min-height: 23rem;
  -webkit-backface-visibility: hidden;
  backface-visibility: hidden; }

.front {
  position: absolute;
  top: 0;
  left: 0;
  height: 100%;
  padding-right: 0;
  padding-left: 0;
  text-align: center;
  transform: rotateY(0deg);
  cursor: pointer; }
  .front p {
    font-size: 1.2rem;
    font-weight: 500;
    letter-spacing: 0.1rem;
    line-height: 1rem;
    text-transform: uppercase;
    color: #ff4444; }
    .front p span {
      display: inline; }
      .front p span ~ :before {
        content: ' · '; }

.back {
  position: relative;
  transform: rotateY(180deg); }
  @media (min-width: 800px) {
    .back .adr ~ * {
      margin-top: 0;
      text-align: right; } }
  @media (min-width: 800px) {
    .back .adr {
      text-align: right; } }
  .back .contact {
    margin-top: 9rem; }
    @media (min-width: 800px) {
      .back .contact {
        display: grid;
        grid-template-rows: 2rem 2rem;
        /*grid-template-columns: 1fr 1fr;*/ } }
  .back .adr {
    /*text-align: right;*/ }
  .back button {
    position: absolute;
    top: 0;
    right: 0;
    padding: 2rem;
    background: #fcfbfa; }

@media (min-width: 800px) {
  .more {
    grid-template-columns: 15rem 1fr; } }

.more aside {
  padding-right: 0;
  grid-column: 1 / 2;
  grid-row: 1 / 999; }

.flipped .more {
  opacity: 1;
  animation: appear 0.5s ease-in; }

@keyframes flip {
  0% {
    transform: rotateZ(0) rotateY(0deg); }
  25% {
    transform: rotateZ(-0.4deg) rotateY(-45deg); }
  50% {
    transform: rotateZ(-4deg) rotateY(-90deg); }
  75% {
    transform: rotateZ(-0.4deg) rotateY(-155deg); }
  100% {
    transform: rotateZ(0) rotateY(-180deg); } }

@keyframes unflip {
  0% {
    transform: rotateZ(0) rotateY(-180deg); }
  25% {
    transform: rotateZ(0.4deg) rotateY(-155deg); }
  50% {
    transform: rotateZ(4deg) rotateY(-90deg); }
  75% {
    transform: rotateZ(0.4deg) rotateY(-45eg); }
  100% {
    transform: rotateZ(0) rotateY(0deg); } }

@keyframes appear {
  0% {
    opacity: 0; }
  100% {
    opacity: 1; } }
