header {
	img {
		display: none;
	}

	p {
		@include h2;
		color: $fg-card-darker;

		span {
			display: block;

			@include media-large {
				display: inline;

				& ~ :before {
					content: ' · ';
				}
			}
		}
	}

	a {
		text-decoration: none;
	}

	+ * {
		margin-top: 3rem;
	}
}

h1 {
	@include h1;

	a {
		cursor: default;

		&:hover,
		&:focus {
			text-decoration: none;
		}
	}
/*	.given-name, .family-name {
		font-size: 5.5rem;
		line-height: 1.2911289006em;
		color: #3c3232;
		font-family: 'Lato', sans-serif;
		font-weight: 300;
		clear: both;
		letter-spacing: 1px;	
		text-shadow: 1px 3px 6px rgba(0, 0, 0, .1);
	} */
}

h2 {
	@include h2;

	margin: 3rem 0 1rem;
	color: $fg-lightest;
}

h3 {
	color: $fg-lighter;
}

.type, .phone {
	position: absolute;
	top: 0;
	left: 0;
	width: 0;
	height: 0;
	overflow: hidden;
}

aside,
section {
	position: relative;
	padding: 0 3rem;
}

.card,
.wrap {
	z-index: 500;
}

.wrap {
	transform: translateZ(0px);
}

.scene {
	position: relative;
	width: 100%;
	min-height: calc(100vh - 3rem);
	transform-origin: 50% 50%;
	perspective-origin: 50% 55%;
	perspective: 80rem;
	display: flex;
	align-items: center;
	justify-content: center;
	flex-flow: column nowrap;
}

.card {
	padding: 3rem;
	background: $bg-card;
	color: $fg-card;
	box-shadow: 0 1px 2px 0 rgba(42, 33, 27, 0.15), 0 2px 4px 0 rgba(42, 33, 27, 0.06),
		0 3px 14px 0 rgba(43, 34, 27, 0.1), 0 6px 35px 0 rgba(43, 34, 28, 0.25);
}

.js {
	.more {
		display: none;
                background: #000000a6;
			@include media-large {
                                background: none;
                        }
	}

	.flipped {
		.more {
			display: grid;
			padding-bottom: 1rem;
		}
	}

	.card,
	.wrap,
	.more {
		max-width: 48rem;
		width: 100%;
	}
}

.fullname {
	max-width: 33rem;
	min-width: 29rem;
	margin: 0 auto;
	max-height: 6.5rem;
	min-height: 5.5rem;
	/*background: $logo 50% 50% no-repeat;*/
	background-size: 100%;

	&:before {
		display: block;
		content: 'Mishal Patel';
		font-family: 'Lato', sans-serif;
		font-size: 5.5rem;
		
		/*padding-bottom: 6.5rem / 33rem * 100%;*/
	}
}

.wrap {
	position: relative;
	transform-style: preserve-3d;
	transform: rotateY(0deg);
}

.flip {
	transform: rotateY(180deg);
	animation: flip 0.6s linear normal;
}

.unflip {
	transform: rotateY(0);
	animation: unflip 0.5s linear normal;
}

.front,
.back {
	min-height: 23rem;
	-webkit-backface-visibility: hidden;
	backface-visibility: hidden;
}

.front {
	position: absolute;
	top: 0;
	left: 0;
	height: 100%;
	padding-right: 0;
	padding-left: 0;
	text-align: center;
	transform: rotateY(0deg);
	cursor: pointer;

	p {
		@include h2(1.2rem, 1rem);
		color: $hl;

		span {
			display: inline;

			& ~ :before {
				content: ' · ';
			}
		}
	}
}

.back {
	position: relative;
	transform: rotateY(180deg);

	.adr {
		& ~ * {
			@include media-large {
				margin-top: 0;
				text-align: right;
			}
		}
		@include media-large {
			text-align: right;
		}
	}

	.contact {
		@include media-large {
			display: grid;
			grid-template-rows: 2rem 2rem;
			/*grid-template-columns: 1fr 1fr;*/
		}

		margin-top: 9rem;
	}

	.adr {
		/*text-align: right;*/
	}

	button {
		position: absolute;
		top: 0;
		right: 0;
		padding: 2rem;
		background: $bg-card;
	}
}
