// Lazy reset, this is a single & self contained page after all
* {
	&,
	&:after,
	&:before {
		margin: 0;
		padding: 0;
		border: 0;
		font: inherit;
		box-sizing: border-box;
		border-radius: 0;
	}
}

html,
body {
	min-height: 100vh;
	background: $bg;
	color: $fg;
	font-family: 'Lato', 'Avenir Next', AvenirNext, Avenir, 'gill sans', 'gill sans mt', Calibri, sans-serif;
	-webkit-tap-highlight-color: rgba(255, 255, 255, 0);
}

html {
	font-size: 10 / 16 * 100%;
}

body {
	padding: 1.5rem;
	font-size: 1.4rem;
	line-height: 2rem;
	background-repeat: no-repeat;
	background: $bg-darkest;
}

a {
	&:link,
	&:visited {
		color: inherit;
		text-decoration: none;
	}

	&:focus,
	&:hover {
		text-decoration: underline;
	}
}

p {
	+ * {
		margin-top: 1rem;
	}
}

ul,
li {
	display: block;
	list-style: none;
}

li {
	+ * {
		margin-top: 1rem;
	}

	> a {
		display: block;
		margin: -0.5rem 0;
		padding: 0.5rem 0;
	}
}
